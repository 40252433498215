import React from 'react';

const TechDesign = ({ className }) => (
<svg className={className} id="tech-design" xmlns="http://www.w3.org/2000/svg" width="345" height="267" viewBox="0 0 345 267" fill="none">
<defs>
      <clipPath id="clipPath">
        <rect width="345" height="267" fill="none" />
      </clipPath>
      <clipPath id="clipPath-2">
        <rect width="345" height="267" fill="none" />
      </clipPath>
    </defs>
<rect x="0.187012" y="5.34058" width="249.418" height="173.767" rx="35" fill="#6BB4D6"/>
<rect x="90.832" y="176.555" width="68.2097" height="32.9189" fill="#6BB4D6"/>
<rect x="26.6489" y="200.426" width="196.576" height="15.6879" rx="7.84395" fill="#6BB4D6"/>
<rect x="0.187012" width="249.418" height="174.975" rx="38" fill="#F5CB4C"/>
<rect x="90.8354" y="174.975" width="68.2092" height="19.9463" fill="#F5CB4C"/>
<rect x="26.0996" y="193.229" width="196.576" height="16.2954" rx="8.1477" fill="#F5CB4C"/>
<rect x="13.3657" y="11.3088" width="222.134" height="123.329" rx="30" fill="#6BB4D6"/>
<rect x="13.5073" y="57.894" width="222.168" height="81.7754" fill="#6BB4D6"/>
<rect x="16.8208" y="16.1174" width="215.313" height="122.527" rx="31" fill="#F9F5E3"/>
<rect x="16.7637" y="59.7031" width="215.294" height="78.8806" fill="#F9F5E3"/>
<ellipse cx="125.786" cy="158.769" rx="7.61342" ry="7.12367" fill="#F9F5E3"/>
<rect x="168.571" y="228.345" width="190.047" height="53.2803" transform="rotate(-44.7987 168.571 228.345)" fill="#6BB4D6"/>
<rect x="242.312" y="129.225" width="101.771" height="49.8699" transform="rotate(44.7408 242.312 129.225)" fill="#F5CB4C"/>
<path d="M85.8031 38.1376L51.7082 75.3271L84.843 114.46M147.079 29.5287L100.919 129.43M165.717 39.1428L200.163 77.1946L164.757 115.465" stroke="#1F1F1F" strokeWidth="15"/>
<path d="M197.392 112L188 122L208.5 165L216 156L224.5 155.5L227.5 146L234 144.5L235.5 137.5L241 132.714L197.392 112Z" fill="#F9F5E3" stroke="black"/>
<path d="M186.73 119.232L177.75 101.871L197.007 109.754L186.73 119.232Z" fill="#1F1F1F" stroke="black"/>
<path d="M335.097 249.739L311.051 267L296.684 257.621L280.32 240.061L277 236L313.5 198.5L335.097 221.802L342.78 240.061L335.097 249.739Z" fill="#F9F5E3"/>
<path d="M209.978 168.122L278.025 236.07L313.745 199.252L292.193 178.421M209.978 168.122L186.805 121.028M209.978 168.122C209.779 163.4 212.333 154.293 224.146 155.65C223.947 151.892 225.703 144.475 234.324 144.874C233.559 140.883 234.643 132.901 245.099 132.901M245.099 132.901L198.704 110.139M245.099 132.901L254.778 142.256M186.805 121.028L175.955 98.9776L198.704 110.139M186.805 121.028L198.704 110.139M229.235 164.131L278.025 215.715M241.707 152.757L292.193 203.243M283.114 238.963L317.736 206.636M287.604 245.748L322.825 211.126M292.193 250.238L328.412 215.715M219.5 177.63L211.066 186.207M254.778 215.715L205.987 264.406L169.17 227.589L182.241 214.678M292.193 178.421L339.188 132.901L303.468 94.3879L292 105.663M292.193 178.421L254.778 142.256M254.778 142.256L262.66 134.507M182.241 214.678L190.383 206.636M182.241 214.678L194.114 227.589M190.383 206.636L209.978 227.589M190.383 206.636L204.172 193.016M204.172 193.016L217.96 206.636M204.172 193.016L211.066 186.207M211.066 186.207L224.146 199.252M262.66 134.507L274.034 123.325M262.66 134.507L282.615 154.553M274.034 123.325L286.706 136.194M274.034 123.325L282.615 114.889M282.615 114.889L300.375 133.201M282.615 114.889L292 105.663M292 105.663L301.173 115.241M297.282 256.524L333.002 221.901C335.795 225.66 341.622 233.755 342.58 236.07C343.778 238.963 321.727 263.807 313.745 264.406C307.359 264.885 300.109 259.351 297.282 256.524Z" stroke="black" strokeWidth="3"/>
</svg>
)

export default TechDesign;
