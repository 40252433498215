import React from 'react'

const CommunityPartners = ({ className }) => (
  <svg className={className} width="986" height="798" viewBox="0 0 986 798" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M779.644 0.0883789L986 200.81L824.722 362.557C791.866 454.538 746.921 525.602 728.556 549.637L463.099 792.257C402.995 814.862 389.98 765.299 390.985 737.692L361.924 764C301.821 785.046 292.137 734.277 294.809 706.262L262.754 737.692C194.636 762.636 191.631 704.563 198.643 672.408L313.842 549.637L232.702 630.51C161.379 642.982 167.589 594.133 179.61 568.15L262.754 474.61C306.496 388.54 402.595 210.164 437.054 185.22C471.513 160.276 570.951 152.091 616.363 151.117L779.644 0.0883789Z" fill="#F5CB4C" />
    <path d="M89.1537 268.928L0 185.132L205.354 0L283.489 78.9245C418.922 136.608 461.462 161.422 465.803 166.618L701.209 393.648C722.846 486.409 665.48 471.053 634.093 451.779L523.903 346.878L256.442 451.137L270.466 470.624L205.354 531.035C101.174 480.368 84.4789 335.186 89.1537 268.928Z" fill="#F5CB4C" />
    <path d="M748.901 21.0829L934.414 206.597L789.425 356.088C759.887 441.101 719.483 506.781 702.972 528.994L464.326 753.232C410.293 774.125 398.592 728.317 399.496 702.801L373.37 727.116C319.337 746.568 310.632 699.646 313.033 673.753L284.215 702.801C222.978 725.855 220.276 672.182 226.58 642.464L330.144 528.994L257.199 603.74C193.08 615.267 198.663 570.12 209.47 546.105L284.215 459.652C323.54 380.103 409.933 215.242 440.912 192.188C471.891 169.134 561.285 161.569 602.111 160.669L748.901 21.0829Z" fill="#6BB4D6" />
    <path d="M396.785 589.331L313.033 673.753M313.033 673.753L284.215 702.801C222.978 725.855 220.276 672.182 226.58 642.464L330.144 528.994L257.199 603.74C193.08 615.267 198.663 570.12 209.47 546.105L284.215 459.652C323.54 380.103 409.933 215.242 440.912 192.188C471.891 169.134 561.285 161.569 602.111 160.669L748.901 21.0829L934.414 206.597L789.425 356.088C759.887 441.101 719.483 506.781 702.972 528.994L464.326 753.232C410.293 774.125 398.592 728.317 399.496 702.801M313.033 673.753C310.632 699.646 319.337 746.568 373.37 727.116L399.496 702.801M464.326 642.464L399.496 702.801" stroke="#1F1F1F" strokeWidth="15" />
    <path d="M128.149 269.552L48 192.105L232.613 21L302.856 93.9448C424.611 147.257 462.855 170.192 466.757 174.994L678.387 384.823C697.839 470.556 646.267 456.363 618.05 438.55L645.967 465.873C675.505 551.606 621.052 538.925 590.133 521.868L618.05 549.624C663.978 643.282 581.127 622.569 557.713 604.558L528.146 574.296C567.464 666.827 507.237 647.457 472.16 626.171L278.541 437.956L291.149 455.967L232.613 511.801C138.956 464.972 123.947 330.79 128.149 269.552Z" fill="#F9F5E3" />
    <path d="M461.354 393.829L590.133 521.868M590.133 521.868L618.05 549.624C663.978 643.282 581.127 622.569 557.713 604.558L404.619 447.862L527.994 573.939C567.619 666.876 507.282 647.484 472.16 626.171L278.541 437.956L291.149 455.967L232.613 511.801C138.956 464.972 123.947 330.79 128.149 269.552L48 192.105L232.613 21L302.856 93.9448C424.611 147.257 462.855 170.192 466.757 174.994L678.387 384.823C697.839 470.556 646.267 456.363 618.05 438.55M590.133 521.868C621.052 538.925 675.505 551.606 645.967 465.873L618.05 438.55M518.989 341.597L618.05 438.55" stroke="#1F1F1F" strokeWidth="15" />
  </svg>
)

export default CommunityPartners
