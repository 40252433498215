import React from 'react'

const Logo = ({ width = "100%", height = "auto", className }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 2801 2427" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="91" width="2801" height="1951.43" rx="297" fill="#6BB4D6" />
    <rect x="1017.96" y="1982.75" width="766.007" height="369.685" fill="#6BB4D6" />
    <rect x="297.174" y="2250.82" width="2207.58" height="176.178" rx="88.0889" fill="#6BB4D6" />
    <rect width="2801" height="1965" rx="297" fill="#F5CB4C" />
    <rect x="1018" y="1965" width="766" height="224" fill="#F5CB4C" />
    <rect x="291" y="2170" width="2207.58" height="183" rx="91.5" fill="#F5CB4C" />
    <rect x="148" y="127" width="2494.6" height="1385" rx="157" fill="#6BB4D6" />
    <rect x="153.201" y="665.875" width="2494.6" height="920.125" fill="#6BB4D6" />
    <rect x="186.799" y="181" width="2418" height="1376" rx="157" fill="#F9F5E3" />
    <rect x="186.799" y="670.854" width="2418" height="914.146" fill="#F9F5E3" />
    <ellipse cx="1410.5" cy="1783" rx="85.5" ry="80" fill="#F9F5E3" />
    <path d="M1443 665.5L1287 691.5L1196.5 629L1215.5 710.5L1140 730.5L1287 804L1473.5 1124.5L1434.5 1180H1473.5L1443 1583.5H1166L1218 1198L919 769.5L1140 453.5L1443 588V665.5Z" fill="#1F1F1F" />
    <path d="M1374.5 775.5L1239.5 730.5C1236.5 726.5 1233.8 717.9 1247 715.5C1263.5 712.5 1319.5 719 1365.5 707.5C1402.3 698.3 1426.83 703.667 1434.5 707.5L1374.5 775.5Z" fill="#1F1F1F" />
    <path d="M1182 427L1319 251L1443 348.5L1340.5 495.5L1182 427Z" fill="#1F1F1F" />
    <path d="M1374.5 511L1473.5 360.5L1614.5 476L1473.5 665.5V564L1374.5 511Z" fill="#1F1F1F" />
    <path d="M1412.5 787L1634 495.5L1755 609L1551.5 841.5L1412.5 787Z" fill="#1F1F1F" />
    <path d="M1575 867L1778.5 629L1870.5 747.5L1758 844.5L1645.5 941.5L1575 867Z" fill="#1F1F1F" />
    <path d="M1551.5 1180L1340.5 804C1344.33 804 1370.2 808.4 1443 826C1515.8 843.6 1608.33 931 1645.5 972.5L1794.5 855.5C1780.5 1052.7 1689.33 1190.67 1645.5 1235L1655.5 1583.5H1473.5L1508.5 1180H1551.5Z" fill="#1F1F1F" />
    <path d="M1443 665.5L1287 691.5L1196.5 629L1215.5 710.5L1140 730.5L1287 804L1473.5 1124.5L1434.5 1180H1473.5L1443 1583.5H1166L1218 1198L919 769.5L1140 453.5L1443 588V665.5Z" stroke="black" />
    <path d="M1374.5 775.5L1239.5 730.5C1236.5 726.5 1233.8 717.9 1247 715.5C1263.5 712.5 1319.5 719 1365.5 707.5C1402.3 698.3 1426.83 703.667 1434.5 707.5L1374.5 775.5Z" stroke="black" />
    <path d="M1182 427L1319 251L1443 348.5L1340.5 495.5L1182 427Z" stroke="black" />
    <path d="M1374.5 511L1473.5 360.5L1614.5 476L1473.5 665.5V564L1374.5 511Z" stroke="black" />
    <path d="M1412.5 787L1634 495.5L1755 609L1551.5 841.5L1412.5 787Z" stroke="black" />
    <path d="M1575 867L1778.5 629L1870.5 747.5L1758 844.5L1645.5 941.5L1575 867Z" stroke="black" />
    <path d="M1551.5 1180L1340.5 804C1344.33 804 1370.2 808.4 1443 826C1515.8 843.6 1608.33 931 1645.5 972.5L1794.5 855.5C1780.5 1052.7 1689.33 1190.67 1645.5 1235L1655.5 1583.5H1473.5L1508.5 1180H1551.5Z" stroke="black" />
  </svg>
)

export default Logo
